import React from 'react'
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps"
  
import CustomMarker from '../../../images/svg/marker.svg'

export default function googleMap() {

    const snazzyMap = [
        {
            "featureType": "administrative",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#444444"
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#f2f2f2"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "all",
            "stylers": [
                {
                    "saturation": -100
                },
                {
                    "lightness": 45
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#81a9a3"
                },
                {
                    "visibility": "on"
                }
            ]
        }
    ];

    const MapObject = withGoogleMap(props =>
        
        <GoogleMap
            options={{
                styles: snazzyMap,
                mapTypeControl: false,
                streetViewControl: false,
                fullscreenControl: false,
                scrollwheel: false,
            }}
            defaultZoom={12}
            defaultCenter={{ lat: 44.7328085, lng: -79.8590987 }}
        > 

        <Marker
            position={{ lat: 44.7328085, lng: -79.8590987 }}
            icon={{
                url: CustomMarker,
              }}
        />
        </GoogleMap>
      );
          
    return (
        <MapObject 
            containerElement={<div style={{ height: `400px` }} />} 
            mapElement={<div style={{ height: `100%` }} />} 
        />
    )
}