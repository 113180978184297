import React from 'react';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import axios from 'axios'

export default class ContactUs extends React.Component {
    state = {
        formData: {
            name: '',
            business: '',
            email: '',
            number: '',
            message: '',
        },
        submitted: false,
    }

    handleChange = (event) => {
        const { formData } = this.state;
        formData[event.target.name] = event.target.value;
        this.setState({ formData });
    }

    handleSubmit = () => {

        this.setState({ submitted: true }, () => {
            const data = this.state.formData
            axios.post('/.netlify/functions/mail', {
                email: data.email,
                name: data.name,
                phone: data.number,
                message: data.message,
                business: data.business
              }, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                  }                 
              }
            )
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            });
          
        });
    }

    render() {
        const { formData, submitted } = this.state;

        const Style = {
            background: "white",
            fontSize: "1.5rem"
        }        

        return (
            <ValidatorForm
                ref="form"
                onSubmit={this.handleSubmit}
                className="contact-form"
            >
                <div className="name-email-phone field">
                    <div className="name field">
                        <TextValidator
                            label="Name"
                            onChange={this.handleChange}
                            name="name"
                            variant="outlined"
                            value={formData.name}
                            validators={['required']}
                            style={Style}
                            errorMessages={['this field is required']}
                        />
                    </div>
                    <div className="business field">
                        <TextValidator
                            label="Business"
                            onChange={this.handleChange}
                            name="business"
                            variant="outlined"
                            value={formData.business}
                            validators={['required']}
                            style={Style}
                            errorMessages={['this field is required']}
                        />
                    </div>
                    <div className="email field">
                        <TextValidator
                            label="Email"
                            onChange={this.handleChange}
                            name="email"
                            variant="outlined"
                            value={formData.email}
                            validators={['required', 'isEmail']}
                            style={Style}
                            errorMessages={['this field is required', 'email is not valid']}
                        />
                    </div>
                    <div className="phone field">
                        <TextValidator
                            label="Phone"
                            onChange={this.handleChange}
                            name="number"
                            variant="outlined"
                            value={formData.number}
                            validators={['required']}
                            style={Style}
                            errorMessages={['this field is required', 'phone is not valid']}
                        />
                </div>
                </div>
                
                <div className="one-field message field">
                    <TextValidator
                        label="Tell us about your business"
                        onChange={this.handleChange}
                        multiline
                        name="message"
                        variant="outlined"
                        value={formData.message}
                        className="message-field"
                        validators={['required', 'required']}
                        style={Style}
                        errorMessages={['this field is required']}
                    />
                </div>
                <Button 
                    variant="contained" 
                    type="submit"
                    className="submit-button"
                    disabled={submitted}
                >
                    {
                        (submitted && 'Your message sent!')
                        || (!submitted && 'Submit')
                    }
                </Button>
                <div className="submit fields">
            </div>
            </ValidatorForm>
        );
    }
}
