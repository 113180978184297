import React from 'react'
import { graphql } from "gatsby"
import { Container, Row, Col } from 'react-grid-system'

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from '../components/rows/hero'
import Intro from '../components/rows/intro'
import Form from '../components/elements/form/form'
import Map from '../components/elements/map'

import Banner from '../images/img/hero/contact-hero.jpg'
import Phone from '../images/svg/phone.svg'
import Mail from '../images/svg/mail.svg'

export default function InvasiveSpecies({data}) { // <== Make sure data is sent to the function

    return (
        <Layout>
            <Seo title="Contact Us" />
            <Hero 
                title="Contact"
                banner={Banner}
            />
            <div className="intro py-56">
                <Intro
                    title='We are here for you.'
                    subtitle='Have a question?'
                    description='With a commitment to service excellence, we are here to help. Get in touch today with the team at RF Kiln Tech.'
                />
            </div>
            <Container>
                <Row>
                    <Col md={2}></Col>
                    <Col md={4} className="my-10">
                        <h5 className="text-green-500">For all inquiries, reach out to RF Kiln Tech today.</h5>
                        <p>Interested in purchasing a RF Kiln for your shop or want to learn more?</p>
                        <p className="text-green-500"><a href="https://www.google.com/maps/dir//260+Whitfield+Crescent,+Midland,+ON+L4R+5E3/@44.7328085,-79.8590987,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x4d2adad4dd3fcd15:0x5ac927f67a1906b4!2m2!1d-79.85691!2d44.7328047" target="_blank" rel="noopener noreferrer">260 Whitfield Cresent, Midland, Ontario Canada L4R 5E3</a><br />
                        <a href="tel:7055264956" className="inline-flex mt-2"><img src={Phone} className="w-10 mr-3" alt="Phone Icon" />705-526-4956</a><br />
                        <a href="mailto:mark@rfkilntech.com" className="inline-flex mt-2"><img src={Mail} className="w-10 mr-3" alt="Email Icon" /> mark@rfkilntech.com</a></p>
                    </Col>
                    <Col md={5}>
                        <Form />
                    </Col>
                    <Col md={1}></Col>
                </Row>
            </Container>
            <div className="pt-56 -mb-20">
                <Map />
            </div>
        </Layout>    
    )
}